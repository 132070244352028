import {
  getMyNotifications,
  setRead,
  setReadAll,
} from '@/component/header/notifications/notifications-service';
import { MyNotificationInterface } from '@/component/header/notifications/notifications-type';
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export class GlobalStore {
  constructor() {
    makeAutoObservable(this);
    void makePersistable(this, {
      name: 'GlobalStore',
      properties: [],
    });
  }
  loading: boolean = false;
  modalLanguage: boolean = false;
  myNotifications: MyNotificationInterface = { data: [], haveNews: false };
  loadingMyNotification: boolean = false;

  reset = () => {
    runInAction(() => {
      this.loading = false;
      this.modalLanguage = false;
      this.myNotifications = { data: [], haveNews: false };
    });
  };

  setLoadingMyNotification = (loading: boolean) => {
    runInAction(() => {
      this.loadingMyNotification = loading;
    });
  };
  handleLoading = (show: boolean) => {
    runInAction(() => {
      this.loading = show;
    });
  };
  handleModalLanguage = (show: boolean) => {
    runInAction(() => {
      this.modalLanguage = show;
    });
  };

  getMyNotifications = async () => {
    try {
      this.setLoadingMyNotification(true);
      const newNotifications = await getMyNotifications();
      runInAction(() => {
        this.myNotifications = newNotifications;
      });
      this.setLoadingMyNotification(false);
    } catch (error: any) {
      console.log(error.message);
      this.setLoadingMyNotification(false);
    }
  };

  setRead = async (id: number) => {
    try {
      const finalData = this.myNotifications.data.map((row) =>
        row.id === id ? { ...row, isRead: true, readAt: new Date() } : row,
      );
      runInAction(() => {
        this.myNotifications.data = finalData;
      });
      await setRead(id);
      await this.getMyNotifications();
    } catch (error: any) {
      await this.getMyNotifications();
      console.log(error.message);
    }
  };
  setReadAll = async () => {
    try {
      const finalData = this.myNotifications.data.map((row) => ({
        ...row,
        isRead: true,
        readAt: new Date(),
      }));
      runInAction(() => {
        this.myNotifications.data = finalData;
      });
      await setReadAll();
      await this.getMyNotifications();
    } catch (error: any) {
      await this.getMyNotifications();
      console.log(error.message);
    }
  };
}
