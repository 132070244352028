import logo from '@/assets/images/logo/logo-color.svg';
import './style.css';
import { useLocation, useParams } from 'react-router-dom';
import i18n from '@/i18n';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
const TermsOfUse = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const darkmode = queryParams.get('darkmode') === 'dark' ? 'dark' : 'light';
  useEffect(() => {
    document.querySelector('html').classList = darkmode;
  }, [darkmode]);
  const { newLanguage } = useParams();
  const language = newLanguage ?? i18n.language;
  const tGeneral = useTranslation('general', { lng: language }).t;
  document.title = tGeneral('termsOfUse.documentTitle');
  return (
    <div className="dark:bg-darkblack-700 dark:text-white w-full px-4 text-justify overflow-y-scroll max-h-screen">
      <img src={logo} className="w-[300px] hidden md:block" />
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong>{tGeneral('termsOfUse.title')}</strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>{tGeneral('termsOfUse.sectionOne.title')}</strong>
        </li>
      </ul>
      <p className="recuo1">&nbsp;</p>
      <ul>
        <li>
          <strong>{tGeneral('termsOfUse.sectionOne.itemOne.title')}</strong>
        </li>
      </ul>
      <p className="recuo1">{tGeneral('termsOfUse.sectionOne.itemOne.text')}</p>
      <ul>
        <li>
          <strong>{tGeneral('termsOfUse.sectionOne.itemTwo.title')}</strong>
        </li>
      </ul>
      <p className="recuo1">{tGeneral('termsOfUse.sectionOne.itemTwo.text')}</p>
      <ul>
        <li>
          <strong>{tGeneral('termsOfUse.sectionOne.itemThree.title')}</strong>
        </li>
      </ul>
      <ul>
        <li className="recuo1">
          ● {tGeneral('termsOfUse.sectionOne.itemThree.topicOne')}
        </li>
        <li className="recuo1">
          ● {tGeneral('termsOfUse.sectionOne.itemThree.topicTwo')}
        </li>
        <li className="recuo1">
          ● {tGeneral('termsOfUse.sectionOne.itemThree.topicThree')}
        </li>
        <li className="recuo1">
          ● {tGeneral('termsOfUse.sectionOne.itemThree.topicFour')}
        </li>
        <li className="recuo1">
          ● {tGeneral('termsOfUse.sectionOne.itemThree.topicFive')}
        </li>
      </ul>
      <p className="recuo1">&nbsp;</p>
      <ul>
        <li>
          <strong>{tGeneral('termsOfUse.sectionTwo.title')}</strong>
        </li>
      </ul>
      <p className="recuo1">&nbsp;</p>
      <ul>
        <li>
          <strong>{tGeneral('termsOfUse.sectionTwo.itemOne.title')}</strong>
        </li>
      </ul>
      <p className="recuo1">{tGeneral('termsOfUse.sectionTwo.itemOne.text')}</p>
      <ul>
        <li>
          <strong>{tGeneral('termsOfUse.sectionTwo.itemTwo.title')}</strong>
        </li>
      </ul>
      <p className="recuo1">{tGeneral('termsOfUse.sectionTwo.itemTwo.text')}</p>
      <ul>
        <li>
          <strong>{tGeneral('termsOfUse.sectionTwo.itemThree.title')}</strong>
        </li>
      </ul>
      <p className="recuo1">{tGeneral('termsOfUse.sectionTwo.itemThree.text')}</p>
      <ul>
        <li>
          <strong>{tGeneral('termsOfUse.sectionTwo.itemFour.title')}</strong>
        </li>
      </ul>
      <p className="recuo1">{tGeneral('termsOfUse.sectionTwo.itemFour.text')}</p>
      <p className="recuo1">&nbsp;</p>
      <ul>
        <li>
          <strong>{tGeneral('termsOfUse.sectionThree.title')}</strong>
        </li>
      </ul>
      <p className="recuo1">&nbsp;</p>
      {tGeneral('termsOfUse.sectionThree.items', { returnObjects: true }).map((item) => {
        return (
          <>
            <ul>
              <li>
                <strong>{item.title}</strong>
              </li>
            </ul>
            <p className="recuo1">{item.text}</p>
          </>
        );
      })}
      <p className="recuo1">&nbsp;</p>
      <ul>
        <li>
          <strong>{tGeneral('termsOfUse.sectionFour.title')}</strong>
        </li>
      </ul>
      <p className="recuo1">&nbsp;</p>
      {tGeneral('termsOfUse.sectionFour.items', { returnObjects: true }).map((item) => {
        return (
          <>
            <ul>
              <li>
                <strong>{item.title}</strong>
              </li>
            </ul>
            <p className="recuo1">{item.text}</p>
          </>
        );
      })}
      <p className="recuo1">&nbsp;</p>
      <ul>
        <li>
          <strong>{tGeneral('termsOfUse.sectionFive.title')}</strong>
        </li>
      </ul>
      <p className="recuo3">&nbsp;</p>
      {tGeneral('termsOfUse.sectionFive.items', { returnObjects: true }).map((item) => {
        return (
          <>
            <ul>
              <li>
                <strong>{item.title}</strong>
              </li>
            </ul>
            {item.topics ? (
              <ul>
                {item.topics.map((topic) => {
                  return (
                    <li className="recuo1" key={topic}>
                      ● {topic}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p className="recuo1">{item.text}</p>
            )}
          </>
        );
      })}
      <p className="recuo1">&nbsp;</p>
      <ul>
        <li>
          <strong>{tGeneral('termsOfUse.sectionSix.title')}</strong>
        </li>
      </ul>
      <p className="recuo3">&nbsp;</p>
      {tGeneral('termsOfUse.sectionSix.items', { returnObjects: true }).map((item) => {
        return (
          <>
            <ul>
              <li>
                <strong>{item.title}</strong>
              </li>
            </ul>
            {item.topics ? (
              <ul>
                {item.topics.map((topic) => {
                  return (
                    <li className="recuo1" key={topic}>
                      ● {topic}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p className="recuo1">{item.text}</p>
            )}
          </>
        );
      })}
      <p className="recuo1">&nbsp;</p>
      <ul>
        <li>
          <strong>{tGeneral('termsOfUse.sectionSeven.title')}</strong>
        </li>
      </ul>
      <p className="recuo3">&nbsp;</p>
      {tGeneral('termsOfUse.sectionSeven.items', { returnObjects: true }).map((item) => {
        return (
          <>
            <ul>
              <li>
                <strong>{item.title}</strong>
              </li>
            </ul>
            {item.topics ? (
              <ul>
                {item.topics.map((topic) => {
                  return (
                    <li className="recuo1" key={topic}>
                      ● {topic}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p className="recuo1">{item.text}</p>
            )}
          </>
        );
      })}
      <p className="recuo1">&nbsp;</p>
      <ul>
        <li>
          <strong>{tGeneral('termsOfUse.sectionEight.title')}</strong>
        </li>
      </ul>
      <p className="recuo3">&nbsp;</p>
      {tGeneral('termsOfUse.sectionEight.items', { returnObjects: true }).map((item) => {
        return (
          <>
            <ul>
              <li>
                <strong>{item.title}</strong>
              </li>
            </ul>
            {item.topics ? (
              <ul>
                {item.topics.map((topic) => {
                  return (
                    <li className="recuo1" key={topic}>
                      ● {topic}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p className="recuo1">{item.text}</p>
            )}
          </>
        );
      })}

      <p className="recuo1">&nbsp;</p>
      <ul>
        <li>
          <strong>{tGeneral('termsOfUse.sectionNine.title')}</strong>
        </li>
      </ul>
      <p className="recuo3">&nbsp;</p>
      {tGeneral('termsOfUse.sectionNine.items', { returnObjects: true }).map((item) => {
        return (
          <>
            <ul>
              <li>
                <strong>{item.title}</strong>
              </li>
            </ul>
            {item.topics ? (
              <ul>
                {item.topics.map((topic) => {
                  return (
                    <li className="recuo1" key={topic}>
                      ● {topic}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p className="recuo1">{item.text}</p>
            )}
          </>
        );
      })}

      <p className="recuo1">&nbsp;</p>
      <ul>
        <li>
          <strong>{tGeneral('termsOfUse.sectionTen.title')}</strong>
        </li>
      </ul>
      <p className="recuo3">&nbsp;</p>
      {tGeneral('termsOfUse.sectionTen.items', { returnObjects: true }).map((item) => {
        return (
          <div key={item.text}>
            <ul>
              <li>
                <strong>{item.title}</strong>
              </li>
            </ul>
            {item.topics ? (
              <ul>
                {item.topics.map((topic) => {
                  return (
                    <li className="recuo1" key={topic}>
                      {topic}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p className="recuo1">{item.text}</p>
            )}
          </div>
        );
      })}

      <address>&nbsp;</address>
    </div>
  );
};

export default TermsOfUse;
