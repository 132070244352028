import { observer } from 'mobx-react';
import { paramType } from '../master-params-types';
import EmptyStateServicesParams from './empty-state';
import SurvivalSurveysCausesTable from './quality/silviculture/survival-surveys/survival-surveys-causes';
import PlantedAreaReasonsTable from './control/silviculture/planted-area/planted-area-reasons';
import { AppUpdateContent } from './general/app-update';

const ParamsTable = ({ activeParam }: { activeParam: paramType }) => {
  if (activeParam === '') return <EmptyStateServicesParams />;
  return activeParam === 'survivalSurveysCause' ? (
    <SurvivalSurveysCausesTable />
  ) : activeParam === 'plantedAreaReason' ? (
    <PlantedAreaReasonsTable />
  ) : activeParam === 'appUpdate' ? (
    <AppUpdateContent />
  ) : null;
};

export default observer(ParamsTable);
