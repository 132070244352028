import { get, put } from '@/utils/helpers/api_helper';
import {
  GET_MY_NOTIFICATIONS,
  PUT_READ_ALL_NOTIFICATION,
  PUT_READ_NOTIFICATION,
} from '@/utils/helpers/url_helper';

export async function getMyNotifications() {
  const myNotifications = await get(GET_MY_NOTIFICATIONS);
  return myNotifications;
}
export async function setRead(id: number) {
  const myNotifications = await put(PUT_READ_NOTIFICATION + String(id));
  return myNotifications.data;
}
export async function setReadAll() {
  const myNotifications = await put(PUT_READ_ALL_NOTIFICATION);
  return myNotifications.data;
}
