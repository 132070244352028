import i18n, { translationI18n } from '@/i18n';
import { ptBR, es } from 'date-fns/locale'; // Opcional, para usar com português
import { formatDistanceToNow } from 'date-fns';

export function getUTC() {
  // Obter o deslocamento em minutos (relativo ao UTC)
  const timezoneOffset = new Date().getTimezoneOffset(); // Em minutos

  // Converter para horas e minutos
  const offsetHours = Math.floor(Math.abs(timezoneOffset) / 60);
  const offsetMinutes = Math.abs(timezoneOffset) % 60;

  // Formatar no estilo UTC+HH:mm ou UTC-HH:mm
  const sign = timezoneOffset <= 0 ? '+' : '-';
  const formattedOffset = `UTC${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;
  return formattedOffset;
}

export function formatTimeAgo(date: Date) {
  const locale = i18n.language === 'es' ? es : ptBR;
  return formatDistanceToNow(date, {
    addSuffix: true,
    locale: locale,
  });
}

export function compareObjects<T extends Record<string, any>>(
  obj1: T,
  obj2: T,
  fields: Array<{ key: string; label: string }>,
): string {
  const changes: string[] = [];

  fields.forEach((field) => {
    const key = field.key;
    const label = field.label || key;
    const hansSubOject = String(key).includes('.');
    const partsOfKey = String(key).split('.');
    const firstKey = hansSubOject ? partsOfKey[0] : key;
    const secondKey = hansSubOject && partsOfKey.length > 1 ? partsOfKey[1] : '';
    // Se o valor é um objeto, chamamos recursivamente a função para comparar seus campos
    const value1 = obj1[firstKey];
    const value2 = obj2[firstKey];
    if (
      typeof value1 === 'object' &&
      value1 !== null &&
      typeof value2 === 'object' &&
      value2 !== null
    ) {
      const subChanges = compareSubObject(value1, value2, secondKey, label);
      console.log(subChanges);
      if (subChanges !== '') {
        changes.push(subChanges);
      }
    } else if (value1 !== value2) {
      changes.push(
        `${translationI18n('common:utils.compareObjects.field')} ${label}: ${translationI18n('common:utils.compareObjects.of')} ${formatField(value1)} ${translationI18n('common:utils.compareObjects.for')} ${formatField(value2)}`,
      );
    }
  });

  if (changes.length === 0) {
    return translationI18n('common:utils.compareObjects.notChanges');
  }

  return `${changes.length > 1 ? translationI18n('common:utils.compareObjects.changesInFields') : translationI18n('common:utils.compareObjects.changesInField')} ${changes.join(', ')}.`;
}

// Função auxiliar para comparar subobjetos
function compareSubObject<T extends Record<string, any>>(
  obj1: T,
  obj2: T,
  key: keyof T,
  translation: string,
): string {
  // Itera sobre as chaves do subobjeto
  const value1 = obj1[key];
  const value2 = obj2[key];

  if (value1 !== value2) {
    const final = `${translation}: ${translationI18n('common:utils.compareObjects.of')} ${formatField(value1)} ${translationI18n('common:utils.compareObjects.for')} ${formatField(value2)}`;
    return final;
  }

  return 'subChanges';
}

// Função de formatação genérica
function formatField(value: any): string {
  if (typeof value === 'boolean') {
    return value
      ? translationI18n('common:utils.compareObjects.yes')
      : translationI18n('common:utils.compareObjects.no');
  }
  if (value instanceof Date) {
    return value.toLocaleDateString(); // Exemplo: "dd/mm/yyyy"
  }
  if (typeof value === 'number') {
    return value.toLocaleString(); // Exemplo: "1.000" para números grandes
  }
  return String(value); // Caso padrão, trata como string
}
