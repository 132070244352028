//CONFIG
////LOGIN
export const POST_JWT_LOGIN = '/auth';
export const REQUEST_PASSWORD_RECOVERY = '/auth/requestPasswordRecovery';
export const CODE_VERIFY = '/auth/codeVerify';
export const NEW_PASSWORD = '/auth/newPassword';

////MYUSER
export const GET_MYUSER = '/config/myuser';
export const NEW_PASSWORD_AUTENTICADO = '/config/users/newPassword';
export const CONFIRM_PASSWORD = '/config/users/confirmPassword';

////COMPANIES
//////COMPANIESMASTER
export const GET_COMPANYS = '/master/company';
export const POST_COMPANY = '/master/company';
export const PUT_CHANGE_STATUS = '/master/company/changeStatus';
export const GET_PERMISSIONS_COMPANY = '/master/permissionsCompany';
export const POST_PERMISSIONS_COMPANY = '/master/permissionsCompany';

//////COMPANIES USER
export const PUT_CHANGE_IMAGE = '/config/company/changeImage';
export const GET_MYCOMPANY = '/config/mycompany';

////APPUPDATE
export const GET_APP_UPDATES = '/master/appUpdate';

////NOTIFICATIONS
export const GET_NOTIFICATIONS = '/notifications';
export const GET_MY_NOTIFICATIONS = '/notifications/myNotifications';
export const PUT_READ_NOTIFICATION = '/notifications/read/';
export const PUT_READ_ALL_NOTIFICATION = '/notifications/readAll';

////LEVELS
export const GET_LEVELS = '/config/levels';
export const POST_LEVELS = '/config/levels';
export const PUT_ORDER_LEVEL = '/config/levels/updateOrder';

export const GET_PERMISSIONS_LEVELS = '/config/permissionsLevels';
export const POST_PERMISSIONS_LEVELS = '/config/permissionsLevels';

////USERS
export const GET_USERS = '/config/users';
export const POST_USERS = '/config/users';
export const GET_USERS_LEVELS = '/config/users/levels';
export const PUT_USERS_CHANGEIMAGE = '/config/users/changeImage';
export const PUT_USERS_REMOVE_IMAGE = '/config/users/removeImage';
export const PUT_CHANGE_KEY_USER = '/master/users/ChangeKeyUser';
export const PUT_CHANGE_MASTER_USER = '/master/users/ChangeMaster';
export const PUT_CHANGE_LANGUAGE = 'config/users/changeLanguage';
export const PUT_CHANGE_CONFIG = 'config/users/changeUserConfig';

////PARAMS
//////QUALIDADE
////////SILVICULTURA
//////////SURVIVAL SURVEYS
export const GET_SURVIVALSURVEYS_TIMES = '/quality/survivalSurveys/times';
export const POST_SURVIVALSURVEYS_TIMES = '/quality/survivalSurveys/times';
export const GET_SURVIVALSURVEYS_PARAMS = '/quality/survivalSurveys/params';
export const SURVIVALSURVEYS_CAUSES_OF_COMPANY = '/quality/survivalSurveys/causes';
export const SURVIVALSURVEYS_CAUSES_UPDATEALL =
  '/quality/survivalSurveys/causes/updateAll';
export const SURVIVALSURVEYS_CAUSES = '/master/quality/survivalSurveys/causes';
export const SURVIVALSURVEYS_CAUSES_UPLOAD =
  '/master/quality/survivalSurveys/causes/upload';

//////CONTROLE
////////SILVICULTURA
//////////APONTAMENTO DE PLANTIO
export const GET_CONTROL_PLANTED_AREA_GENETIC_MATERIAL =
  '/control/plantedArea/geneticMaterial';
export const PLANTEDAREA_REASONS_OF_COMPANY = '/control/plantedArea/reasons';
export const PLANTEDAREA_REASONS_UPDATE_ALL = '/control/plantedArea/reasons/updateAll';
export const PLANTEDAREA_REASONS = '/master/control/silviculture/reasons';

//CADASTRO FLORESTAL
export const GET_FOREST_REGISTRY = '/forestRegistry';
export const GET_FOREST_REGISTRY_SUMMARY = '/forestRegistry/summary';
export const GET_FOREST_REGISTRY_SUMMARY_BY_YEAR = '/forestRegistry/summaryByYear';
export const GET_FOREST_REGISTRY_OPTIONS = '/forestRegistry/options/';
export const FOREST_REGITRY_CHANGE_STATUS = '/forestRegistry/changeStatus';
export const POST_FOREST_REGISTRY_VALID_FILE_EXCEL_IMPORT = '/forestRegistry/validExcel';
export const POST_FOREST_REGISTRY_VALID_FILE_KML_IMPORT = '/forestRegistry/validKML';
export const POST_FOREST_REGISTRY_UPLOAD_EXCEL_IMPORT = '/forestRegistry/uploadExcel';
export const POST_FOREST_REGISTRY_UPDATE_LIST_COORDINATES =
  '/forestRegistry/updateListCoordinates';
export const POST_FOREST_REGISTRY_UPDATE_COORDINATES =
  '/forestRegistry/updateCoordinates';
export const DASHBOARD_GET_FOREST_REGISTRY_FILTERS = '/dashboard/forestRegistry/filters';
export const DASHBOARD_GET_FOREST_REGISTRY_SUMMARY = '/dashboard/forestRegistry/summary';
export const DASHBOARD_GET_FOREST_REGISTRY_CHART_PLANTED_AREA =
  '/dashboard/forestRegistry/chartPlantedArea';

//QUALIDADE
////SILVICULTURE
//////SURVIVALSURVEYS
export const GET_QUALITY_SURVIVAL_SURVEYS = '/quality/survivalSurveys';
export const GET_QUALITY_SURVIVAL_SURVEYS_REACTIVE =
  '/quality/survivalSurveys/reactiveEvaluation/';
export const GET_QUALITY_SURVIVAL_SURVEYS_BY_COD = '/quality/survivalSurveys/byCod';
export const GET_QUALITY_SURVIVAL_SURVEYS_CAUSES_DEATH_MOBILE =
  '/quality/survivalSurveys/causes/mobile';
export const GET_QUALITY_SURVIVAL_SURVEYS_APPROVALS =
  '/quality/survivalSurveys/approvals';
export const GET_QUALITY_SURVIVAL_SURVEYS_APPROVALS_ARR =
  '/quality/survivalSurveys/approvalsArr';
export const GET_QUALITY_SURVIVAL_SURVEYS_LIST_STATUS =
  '/quality/survivalSurveys/listStatus';
export const GET_QUALITY_SURVIVAL_SURVEYS_PENDENCIES =
  '/quality/survivalSurveys/pendencies';
export const GET_QUALITY_SURVIVAL_SURVEYS_SUMMARY_BY_YEAR =
  '/quality/survivalSurveys/summaryByYear';
export const GET_QUALITY_SURVIVAL_SURVEYS_MY_APPROVALS =
  '/quality/survivalSurveys/myApprovals';
export const PUT_QUALITY_SURVIVAL_SURVEYS_UPDATE_CAUSE =
  '/quality/survivalSurveys/dotDeath/';
export const PUT_QUALITY_SURVIVAL_SURVEYS_UPDATE_IMAGE_MAP =
  '/quality/survivalSurveys/updateIntepolatedImageMap/';
export const DASHBOARD_GET_SURVIVAL_SURVEYS_FILTERS =
  '/dashboard/survivalSurveys/filters';
export const DASHBOARD_GET_SURVIVAL_SURVEYS_DATA_CHART =
  '/dashboard/survivalSurveys/survival';
export const DASHBOARD_GET_SURVIVAL_SURVEYS_DATA_MAP =
  '/dashboard/survivalSurveys/survivalMap';
export const DASHBOARD_GET_SURVIVAL_SURVEYS_DATA_CAUSES =
  '/dashboard/survivalSurveys/deathCauses';
export const DASHBOARD_GET_SURVIVAL_SURVEYS_SUMMARY =
  '/dashboard/survivalSurveys/summary';

//CONTROLE
////SILVICULTURE
//////APONTAMENTO DE PLANTIO
export const GET_CONTROL_SILVICULTURE_PLANTED_AREA_NOTE_LIST_STATUS =
  '/control/plantedArea/notes/listStatus';
export const GET_CONTROL_SILVICULTURE_PLANTED_AREA_NOTE = '/control/plantedArea/notes';
export const GET_CONTROL_SILVICULTURE_PLANTED_NOTE_BY_COD =
  '/control/plantedArea/notes/byCod';
export const GET_CONTROL_SILVICULTURE_PLANTED_AREA_PARAMS = '/control/plantedArea/params';
export const GET_CONTROL_SILVICULTURE_PLANTED_APPROVALS =
  '/control/plantedArea/approvals';
export const GET_CONTROL_SILVICULTURE_PLANTED_APPROVALS_ARR =
  '/control/plantedArea/approvalsArr';
export const GET_CONTROL_SILVICULTURE_PLANTED_MY_APPROVALS =
  '/control/plantedArea/notes/myApprovals';
export const GET_CONTROL_SILVICULTURE_PLANTED_REASONS_MOBILE =
  '/control/plantedArea/reasons/mobile';
export const GET_CONTROL_SILVICULTURE_PLANTED_GENETIC_MATERIALS_MOBILE =
  '/control/plantedArea/geneticMaterial/mobile';
export const GET_CONTROL_SILVICULTURE_PLANTED_AREA_NOTE_REACTIVE =
  '/control/plantedArea/notes/reactive/';
export const GET_CONTROL_SILVICULTURE_PLANTED_AREA_NOTE_INACTIVE =
  '/control/plantedArea/notes/inactive/';

export const GET_MAP_DATES_IMAGES = '/services/maps/dates';
export const GET_MAP_IMAGES = 'services/maps/layers/';
