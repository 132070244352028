import { del, get, post, put } from '@/utils/helpers/api_helper';
import {
  AppUpdateInterfaceSave,
  getListAppUpdatesDataInterface,
  getListAppUpdatesResponseInterface,
  SaveOrUpdateAppUpdateInterface,
} from './master-params-general-app-update-types';
import { GET_APP_UPDATES } from '@/utils/helpers/url_helper';

export async function getListAppUpdates(
  data: getListAppUpdatesDataInterface,
): Promise<getListAppUpdatesResponseInterface> {
  const result = await get(GET_APP_UPDATES, { params: data });
  return result;
}
export async function saveAppUpdate(
  data: AppUpdateInterfaceSave,
): Promise<SaveOrUpdateAppUpdateInterface> {
  const result = await post(GET_APP_UPDATES, data);
  return result;
}
export async function updateAppUpdate(
  data: AppUpdateInterfaceSave,
): Promise<SaveOrUpdateAppUpdateInterface> {
  const result = await put(GET_APP_UPDATES + '/' + data?.id, data);
  return result;
}
export async function deleteAppUpdate(
  id: number,
): Promise<SaveOrUpdateAppUpdateInterface> {
  const result = await del(GET_APP_UPDATES + '/' + id);
  return result;
}
