// import { Toast } from '@/component/Toast';
import { makeAutoObservable, makeObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import {
  DataRequestType,
  FilterDataType,
  FiltersOptionsType,
  FilterStatusType,
  LoadingType,
  ModalStatesType,
  OptionFilterType,
  PlantedAreaGeneticMaterialType,
  PlantedAreaNoteInterface,
  PlantedAreaNoteListInterface,
  PlantedAreaReasonType,
} from './planted-area-types';
import {
  initialSateModalState,
  initialStatePlantedAreaFilters,
  initialStatePlantedAreaLoading,
  initialStatePlantedAreaNoteList,
  initialStatePlantedAreaNotes,
  initialStatePlantedAreaParams,
  inititalStatePlantedAreaDetailsNote,
} from './planted-area-initial-states';
import {
  approveNoteArr,
  approveNote,
  getListStatus,
  getMyApprovals,
  getPlantedAreaNotes,
  getPlantedAreaReasons,
  getPlantedAreaGeneticMaterials,
  getPlantedAreaByCod,
  reactiveNote,
  inactiveNote,
} from './planted-area-services';
import { userStore } from '@/store';
import { translationI18n } from '@/i18n';
import { formatNumber } from '@/utils/helpers/formaters';
import { OrderTableTypes } from '@/component/Table/types';
import { Toast } from '@/component/Toast';
import { PlantedAreaParamsType } from '@/pages/private/config/company-profile/company-profile-body/services-params/services-params-types';
import { getPlantedAreaParams } from '@/pages/private/config/company-profile/company-profile-body/services-params/services-params-services';

const { showSuccess } = Toast;
export class PlantedAreaNoteStore {
  constructor() {
    makeAutoObservable(this);
    makeObservable(this);
    void makePersistable(this, {
      name: 'PlantedAreaStore',
      properties: [],
    });
  }

  listNotes: { [index in FilterStatusType]: PlantedAreaNoteListInterface } = {
    all: initialStatePlantedAreaNotes,
    approved: initialStatePlantedAreaNotes,
    flowApproved: initialStatePlantedAreaNotes,
    error: initialStatePlantedAreaNotes,
  };
  detailsNote: PlantedAreaNoteInterface = inititalStatePlantedAreaDetailsNote;
  filter: FilterDataType = initialStatePlantedAreaFilters;
  filterStatus: FilterStatusType = 'flowApproved';
  filters: FiltersOptionsType = {
    fazenda: [],
    regiao: [],
    talhao: [],
    user: [],
    company: [],
    geneticMaterial: [],
  };
  filtersStatus: OptionFilterType[] = [];
  loading: LoadingType = initialStatePlantedAreaLoading;
  dataRequest: DataRequestType = {
    flowApproved: initialStatePlantedAreaNoteList,
    approved: initialStatePlantedAreaNoteList,
    all: initialStatePlantedAreaNoteList,
    error: initialStatePlantedAreaNoteList,
  };
  selectedRows: number[] = [];
  myApprovals: PlantedAreaNoteInterface[] = [];
  modalStates: ModalStatesType = initialSateModalState;
  params: PlantedAreaParamsType = initialStatePlantedAreaParams;
  genetics: PlantedAreaGeneticMaterialType[] = [];
  reasons: PlantedAreaReasonType[] = [];

  noteNotFound: boolean = false;
  reset = () => {
    this.listNotes = {
      all: initialStatePlantedAreaNotes,
      approved: initialStatePlantedAreaNotes,
      flowApproved: initialStatePlantedAreaNotes,
      error: initialStatePlantedAreaNotes,
    };
    this.filter = initialStatePlantedAreaFilters;
    this.filterStatus = 'flowApproved';
    this.filters = {
      fazenda: [],
      regiao: [],
      talhao: [],
      user: [],
      company: [],
    };
    this.filtersStatus = [];
    this.loading = initialStatePlantedAreaLoading;
    this.dataRequest = {
      flowApproved: initialStatePlantedAreaNoteList,
      approved: initialStatePlantedAreaNoteList,
      all: initialStatePlantedAreaNoteList,
      error: initialStatePlantedAreaNoteList,
    };
    this.selectedRows = [];
    this.myApprovals = [];
    this.modalStates = initialSateModalState;
    this.params = initialStatePlantedAreaParams;
    this.genetics = [];
    this.reasons = [];
    this.noteNotFound = false;
    this.detailsNote = inititalStatePlantedAreaDetailsNote;
  };
  getNoteDetails = async (cod: number) => {
    this.handleLoading('details', true);
    try {
      const responseDetails = await getPlantedAreaByCod(cod, userStore.companyActive.id);
      runInAction(() => {
        this.detailsNote = responseDetails;
        this.noteNotFound = false;
      });
      this.handleLoading('details', false);
    } catch (error: any) {
      console.error(error.message);
      if (error.response.status === 404) {
        runInAction(() => {
          this.noteNotFound = true;
        });
      }

      this.handleLoading('details', false);
    }
  };
  handleLoading = (key: keyof LoadingType, loading: boolean) => {
    try {
      runInAction(() => {
        this.loading[key] = loading;
      });
    } catch (error: any) {
      console.error(error.message);
    }
  };

  async getOptionsForm() {
    this.handleLoading('optionsForm', true);
    try {
      const companyId = userStore.companyActive.id;
      const params = await getPlantedAreaParams(companyId);
      const reasons = await getPlantedAreaReasons({ companyId });
      const geneticMaterials = await getPlantedAreaGeneticMaterials({ companyId });
      runInAction(() => {
        this.params = params;
        this.reasons = reasons;
        this.genetics = geneticMaterials;
        this.handleLoading('optionsForm', false);
      });
    } catch (error: any) {
      this.handleLoading('optionsForm', false);
      console.error(error.message);
    }
  }

  handleFilterEvaluation = (
    filter: keyof FilterDataType,
    value: string | number | boolean | string[] | number[] | boolean[],
  ) => {
    runInAction(() => {
      this.filter = {
        ...this.filter,
        [filter]: value,
      };

      this.dataRequest = {
        all: {
          ...this.dataRequest.all,
          page: 1,
        },
        approved: {
          ...this.dataRequest.approved,
          page: 1,
        },
        flowApproved: {
          ...this.dataRequest.flowApproved,
          page: 1,
        },
        error: {
          ...this.dataRequest.error,
          page: 1,
        },
      };
    });
  };

  handleClearFilterEvaluation = () => {
    runInAction(() => {
      this.filter = initialStatePlantedAreaFilters;
    });
  };
  handleModal = (
    key: keyof ModalStatesType,
    visible: boolean,
    note: PlantedAreaNoteInterface | undefined = undefined,
  ) => {
    runInAction(() => {
      this.modalStates = {
        ...this.modalStates,
        note: note,
        [key]: visible,
      };
      if (key === 'edit') {
        this.getOptionsForm();
      }
    });
  };
  setSelectedRows = (rows: number[]) => {
    runInAction(() => {
      this.selectedRows = rows;
    });
  };

  clearSelectedRows = () => {
    runInAction(() => {
      this.selectedRows = [];
    });
  };
  handleNoteNotFound = (newStatus: boolean) => {
    runInAction(() => {
      this.noteNotFound = newStatus;
    });
  };
  handleFilterStatus = (status: FilterStatusType) => {
    try {
      runInAction(() => {
        this.filterStatus = status;
      });
    } catch (error: any) {
      console.error(error.message);
      /* empty */
    }
  };
  handleDataRequest = (
    status: FilterStatusType,
    propertie: string,
    value: number | string | OrderTableTypes,
  ) => {
    this.dataRequest[status] = { ...this.dataRequest[status], [propertie]: value };
  };
  getDataFilters = async () => {
    this.handleLoading('statusFilter', true);

    const responseFilters = await getListStatus({
      ...this.filter,
      ...this.dataRequest[this.filterStatus],
      companyId: userStore.companyActive.id,
    });
    try {
      const newFilters = Object.keys(responseFilters).map((keyFilter) => {
        const filter = responseFilters[keyFilter as FilterStatusType];
        return {
          label: `${keyFilter === 'approved' ? translationI18n('plantedArea:listNotes.labelsTab.approved') : keyFilter === 'flowApproved' ? translationI18n('plantedArea:listNotes.labelsTab.inApprove') : keyFilter === 'error' ? translationI18n('plantedArea:listNotes.labelsTab.error') : translationI18n('plantedArea:listNotes.labelsTab.all')} (${formatNumber(filter, 0)})`,
          value: keyFilter as FilterStatusType,
        };
      }) as unknown as OptionFilterType[];
      runInAction(() => {
        this.filtersStatus = newFilters;
        this.handleLoading('statusFilter', false);
      });
    } catch (error: any) {
      console.error(error.message);
      /* empty */
    }
  };
  getDataTable = async () => {
    try {
      runInAction(() => {
        this.clearSelectedRows();
        this.handleLoading('table', true);
      });
      const response = await getPlantedAreaNotes({
        ...this.filter,
        filterStatus: this.filterStatus,
        ...this.dataRequest[this.filterStatus],
        ...this.dataRequest[this.filterStatus].order,
        companyId: userStore.companyActive.id,
      });
      runInAction(() => {
        this.listNotes[this.filterStatus] = response;
        this.filters = response.filters;
        this.handleLoading('table', false);
      });
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('table', false);
    }
  };
  getMyListApprovals = async () => {
    this.handleLoading('myApprovals', true);
    try {
      const responseMyApprovals = await getMyApprovals({
        companyId: userStore.user.company.id,
      });
      runInAction(() => {
        this.myApprovals = responseMyApprovals;
        this.handleLoading('myApprovals', false);
      });
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('myApprovals', false);
    }
  };

  approveNote = async () => {
    this.handleLoading('modalApprove', true);
    try {
      const response = await approveNote({
        noteId: Number(this.modalStates.note?.id),
      });

      await this.getMyListApprovals();
      await this.getDataTable();
      showSuccess(response);
      this.handleModal('approve', false);
      this.handleLoading('modalApprove', false);
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('modalApprove', false);
    }
  };
  approveArrNotes = async () => {
    this.handleLoading('modalApprove', true);
    try {
      const notes = this.listNotes[this.filterStatus].plantingNotes
        .filter((evaluation, index) => this.selectedRows.includes(index))
        .map((row) => row.id);
      await approveNoteArr({ notes });
      this.handleModal('approveMultiple', false);
      this.handleLoading('modalApprove', false);
      await this.getDataTable();
      await this.getDataFilters();
      await this.getMyListApprovals();
      runInAction(() => {
        this.selectedRows = [];
      });
    } catch (error: any) {
      console.log(error.message);
      this.handleModal('approveMultiple', false);
      this.handleLoading('modalApprove', false);
    }
  };

  onReactiveNote = async () => {
    this.handleLoading('modalReactive', true);
    try {
      if (this.modalStates.note) {
        await reactiveNote(this.modalStates.note?.id);
      }
      this.handleLoading('modalReactive', false);
      this.handleModal('reactive', false);
      await this.getDataTable();
      await this.getDataFilters();
      await this.getMyListApprovals();
    } catch (error: any) {
      console.log(error.message);
      this.handleLoading('modalReactive', false);
    }
  };
  onInactiveNote = async () => {
    this.handleLoading('modalInactive', true);
    try {
      if (this.modalStates.note) {
        await inactiveNote(this.modalStates.note?.id);
      }
      this.handleLoading('modalInactive', false);
      this.handleModal('inactive', false);
      await this.getDataTable();
      await this.getDataFilters();
      await this.getMyListApprovals();
    } catch (error: any) {
      console.log(error.message);
      this.handleLoading('modalInactive', false);
    }
  };
}
