import { translationI18n } from '@/i18n';
import {
  FilterDataType,
  LoadingType,
  ModalStatesType,
  PlantedAreaNoteInterface,
  PlantedAreaNoteListInterface,
} from './planted-area-types';
import { ColumnsTypes } from '@/component/Table/types';
import { formatNumber } from '@/utils/helpers/formaters';
import { PlantedAreaParamsType } from '@/pages/private/config/company-profile/company-profile-body/services-params/services-params-types';
import BadgeStatusApprovals from './components/badge-status-approvals';

export const inititalStatePlantedAreaDetailsNote: PlantedAreaNoteInterface = {
  coordinates: {
    lat: 0,
    lng: 0,
  },
  id: 0,
  cod: 0,
  uuid: '',
  companyId: 0,
  userId: 0,
  forestRegistryId: 0,
  reasonId: 0,
  geneticMaterialId: 0,
  source: 'mobile',
  area: 0,
  seedlingsUsed: 0,
  seedlingsDiscarded: 0,
  plantingDensity: 0,
  percentageDiscarded: 0,
  plantingEnd: false,
  totalPlantedArea: 0,
  availableArea: 0,
  observation: '',
  error: false,
  errorMessage: '',
  levelIdLastAction: null,
  levelIdNextAction: 0,
  approved: false,
  active: false,
  createdAtMobile: new Date(),
  createdAt: new Date(),
  updatedAt: new Date(),
  user: {
    id: 0,
    name: '',
    email: '',
    active: false,
    master: false,
    keyUser: false,
    contact: '',
    newUser: '',
    imageName: '',
    imageUrl: '',
    config: {
      mobileDarkMode: false,
      mobileVibration: false,
      mobileSound: false,
      webDarkMode: false,
    },
    level: {
      id: 0,
      levelName: '',
      level: 0,
      permissions: [],
    },
    company: {
      id: 0,
      name: '',
      doc: '',
      contact: '',
      manager: '',
      imageName: '',
      imageUrl: '',
      active: false,
      language: 'pt-br',
      createdAt: new Date(),
      updatedAt: new Date(),
      levels: [],
      permissions: [],
    },
    language: 'pt-br',
    createdAt: new Date(),
    updatedAt: '',
  },
  approvals: [],
  lastLevelApproved: null,
  nextLevelApproved: null,
  forestRegistry: {
    id: 0,
    companyId: 0,
    regiao: '',
    fazenda: '',
    talhao: '',
    area: 0,
    manejo: '',
    ciclo: 0,
    rotacao: 0,
    dataPlantio: '',
    active: false,
    geneticMaterial: null,
    spacingBetweenPlants: null,
    spacingBetweenLines: null,
    registryCompanyId: '',
    plantedArea: 0,
    plantingEnd: false,
    createdAt: '',
    updatedAt: '',
    coordinates: [],
  },
  reason: {
    id: 0,
    reason: '',
    description: '',
    color: '',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  geneticMaterial: {
    id: 0,
    cod: 0,
    companyId: 0,
    genetic: '',
    active: false,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  logs: [],
  targetDiscardedPlants: 0,
  targetPlantingDensity: 0,
};

export const initialStatePlantedAreaNotes: PlantedAreaNoteListInterface = {
  totalItems: 0,
  plantingNotes: [],
  totalPages: 0,
  currentPage: 0,
  filters: {
    fazenda: [],
    regiao: [],
    talhao: [],
    user: [],
    company: [],
  },
};

export const initialStatePlantedAreaFilters: FilterDataType = {
  fazenda: [],
  regiao: [],
  talhao: [],
  query: '',
  company: [],
  active: [],
};
export const initialStatePlantedAreaLoading: LoadingType = {
  statusFilter: false,
  table: false,
  tableExport: false,
  modalApprove: false,
  myApprovals: true,
  optionsForm: false,
  modalEdit: false,
  details: false,
  modalInactive: false,
  modalReactive: false,
};
export const initialStatePlantedAreaNoteList: {
  page: number;
  size: number;
  companyId?: number;
  order: { column: string; direction: 'asc' | 'desc' };
} = {
  page: 1,
  size: 10,
  order: { column: 'id', direction: 'desc' },
};

export const initialSateModalState: ModalStatesType = {
  history: false,
  note: undefined,
  approve: false,
  details: false,
  approveMultiple: false,
  inactive: false,
  reactive: false,
  edit: false,
};

export const initialStatePlantedAreaParams: PlantedAreaParamsType = {
  targetPlantingDensityMinError: 0,
  targetPlantingDensityMinAlert: 0,
  targetPlantingDensity: 0,
  targetPlantingDensityMaxAlert: 0,
  targetPlantingDensityMaxError: 0,
  targetDiscardedPlantsMinError: 0,
  targetDiscardedPlantsMinAlert: 0,
  targetDiscardedPlants: 0,
  targetDiscardedPlantsMaxAlert: 0,
  targetDiscardedPlantsMaxError: 0,
};
const locationRow = (row: PlantedAreaNoteInterface) => {
  return (
    <div className="items-center justify-center flex  dark:text-white">
      <div className="flex justify-end items-center w-[55px] pr-2">
        {/* <IoLocationOutline size={20} /> */}
        <div>
          <p className="text-lg text-right">{row.forestRegistry.talhao}</p>
          <p className="text-sm mt-[-7px] text-right">
            {formatNumber(row.forestRegistry.area, 2)}ha
          </p>
        </div>
      </div>

      <div className="border-l-[1px] flex-1 flex-col pl-2">
        <p className="text-lg">{row.forestRegistry.fazenda}</p>
        <p className="text-sm mt-[-7px]">{row.forestRegistry.regiao}</p>
      </div>
    </div>
  );
};
export const columnsState: () => ColumnsTypes[] = () => [
  {
    title: translationI18n('plantedArea:listNotes.table.columns.cod'),
    field: 'cod',
    type: 'int',
  },
  {
    title: translationI18n('plantedArea:listNotes.table.columns.talhao'),
    field: 'forestRegistry.fazenda',
    type: 'custom',
    custom: locationRow,
  },
  {
    title: translationI18n('plantedArea:listNotes.table.columns.plantedArea'),
    field: 'area',
    type: 'custom',
    custom: (row: PlantedAreaNoteInterface) => {
      return formatNumber(row.area, 2);
    },
  },
  {
    title: translationI18n('plantedArea:listNotes.table.columns.percentagePlantedArea'),
    field: 'area',
    type: 'custom',
    custom: (row: PlantedAreaNoteInterface) => {
      return formatNumber((row.totalPlantedArea / row.forestRegistry.area) * 100, 2);
    },
  },
  {
    title: translationI18n('plantedArea:listNotes.table.columns.seedlingsUseds'),
    field: 'seedlingsUsed',
    type: 'custom',
    custom: (row: PlantedAreaNoteInterface) => {
      return formatNumber(row.seedlingsUsed, 0);
    },
  },
  {
    title: translationI18n('plantedArea:listNotes.table.columns.seedlingsDiscarded'),
    field: 'percentageDiscarded',
    type: 'custom',
    custom: (row: PlantedAreaNoteInterface) => {
      // return formatNumber(row.seedlingsDiscarded, 0);
      return (
        <div className="flex gap-1 items-baseline">
          <div>{formatNumber(row.seedlingsDiscarded, 0)}</div>
          <div className="text-sm">{`${formatNumber(row.percentageDiscarded, 2)}%`}</div>
        </div>
      );
    },
  },
  {
    title: translationI18n('plantedArea:listNotes.table.columns.densintyPlant'),
    field: 'seedlingsDiscarded',
    type: 'custom',
    custom: (row: PlantedAreaNoteInterface) => {
      return (
        <div className="flex gap-1 items-baseline">
          <div>{formatNumber(row.seedlingsUsed / row.area, 0)}</div>
          <div className="text-sm">
            {translationI18n('plantedArea:listNotes.table.columns.densintyPlantSuffix')}
          </div>
        </div>
      );
    },
  },
  {
    title: translationI18n('plantedArea:listNotes.table.columns.approve'),
    field: 'nextLevelApproved.level',
    custom: (row: PlantedAreaNoteInterface) => <BadgeStatusApprovals note={row} />,
    type: 'custom',
  },
  {
    title: translationI18n('plantedArea:listNotes.table.columns.user'),
    field: 'user.name',
    type: 'string',
  },
  {
    title: translationI18n('plantedArea:listNotes.table.columns.createdAt'),
    field: 'createdAt',
    type: 'datetime',
  },
  {
    title: translationI18n('plantedArea:listNotes.table.columns.status'),
    field: 'active',
    type: 'active',
  },
  {
    title: translationI18n('plantedArea:listNotes.table.columns.actions'),
    field: 'options',
    type: 'options',
  },
];
