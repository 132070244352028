import { Link } from 'react-router-dom';
import { NotificationInterface } from '../../notifications-type';
import { translationI18n } from '@/i18n';
import { IoAlert, IoCheckmark, IoClose, IoInformation } from 'react-icons/io5';
import { formatTimeAgo } from '@/utils/utils';
import { observer } from 'mobx-react';
import { globalStore } from '@/store';
export const NotificationItem = observer(
  ({ notification }: { notification: NotificationInterface }) => {
    const { setRead } = globalStore;
    const getColor = () => {
      switch (notification?.type) {
        case 'danger':
          return notification?.isRead ? 'text-error-50' : 'text-error-200';

        case 'success':
          return notification?.isRead ? 'text-success-50' : 'text-success-200';

        case 'info':
          return notification?.isRead ? 'text-bgray-600' : ' dark:text-white';

        case 'warning':
          return notification?.isRead ? 'text-warning-50' : 'text-warning-300';
      }
    };
    const Icon = () => {
      switch (notification?.type) {
        case 'danger':
          return <IoClose className={`${getColor()}`} />;

        case 'success':
          return <IoCheckmark className={`${getColor()}`} />;

        case 'warning':
          return <IoAlert className={`${getColor()}`} />;

        case 'info':
          return <IoInformation className={`${getColor()}`} />;
      }
    };
    return (
      <li className="border-b border-bgray-200 py-2 pl-6 pr-[16px] hover:bg-bgray-100 dark:border-darkblack-400 dark:hover:bg-darkblack-500">
        <Link to="#">
          <div className="noti-item">
            <div className="flex items-center">
              <Icon />
              <div
                className={`text-lg ${getColor()} ${notification?.isRead ? '' : 'font-semibold '}`}
              >
                {notification?.title}
              </div>
            </div>
            <div
              className={`text-sm ${notification?.isRead ? 'font-extralight text-bgray-600' : 'font-semibold dark:text-white'}`}
            >
              {notification?.body}
            </div>
            <span className="text-xs font-medium text-bgray-500 flex justify-between">
              <div>
                {`${translationI18n('general:notifications.received')} ${formatTimeAgo(notification?.createdAt)}`}
              </div>
              {notification?.isRead && notification?.readAt ? (
                <div>
                  {`${translationI18n('general:notifications.read')} ${formatTimeAgo(notification?.readAt)}`}
                </div>
              ) : (
                <div
                  className="hover:underline text-primary-200"
                  onClick={async () => await setRead(notification?.id)}
                >
                  {translationI18n('general:notifications.checkRead')}
                </div>
              )}
            </span>
          </div>
        </Link>
      </li>
    );
  },
);
