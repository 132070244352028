import Table from '@/component/Table';
import { translationI18n } from '@/i18n';
import { useTranslation } from 'react-i18next';
import EmptyState from '@/component/EmptyState';
import { appUpdateStore } from '@/store';
import { ColumnsTypes, OptionsType } from '@/component/Table/types';
import { AppUpdateInterface } from '../master-params-general-app-update-types';
import { observer } from 'mobx-react';
import { getUTC } from '@/utils/utils';

const AppUpdateTable = observer(() => {
  const {
    loading,
    handleModalState,
    listAppUpdates,
    page,
    size,
    order,
    setPage,
    setSize,
    setOrder,
  } = appUpdateStore;
  const tMaster = useTranslation('master').t;
  const columns: ColumnsTypes[] = [
    {
      title: tMaster('paramsPage.params.general.columns.cod'),
      field: 'id',
      type: 'int',
      ordenable: false,
    },
    {
      title: tMaster('paramsPage.params.general.columns.app'),
      field: 'app',
      type: 'string',
      ordenable: false,
    },
    {
      title: tMaster('paramsPage.params.general.columns.version'),
      field: 'version',
      type: 'string',
      ordenable: false,
    },
    {
      title: tMaster('paramsPage.params.general.columns.updateDate', { utc: getUTC() }),
      field: 'updateDate',
      type: 'datetime',
      ordenable: false,
    },
    {
      title: tMaster('paramsPage.params.general.columns.active'),
      field: 'active',
      type: 'active',
      ordenable: false,
    },

    {
      title: tMaster('paramsPage.params.general.columns.actions'),
      field: 'c',
      type: 'options',
      ordenable: false,
    },
  ];
  const options = (row: AppUpdateInterface): OptionsType[] => {
    return [
      {
        label: translationI18n('master:paramsPage.params.general.columns.releases'),
        action: () => {
          handleModalState('releases', true, row);
        },
      },
      {
        label: translationI18n('common:actions.edit'),
        action: () => {
          handleModalState('add', true, row);
        },
      },
      {
        label: translationI18n('common:actions.delete'),
        action: () => {
          handleModalState('delete', true, row);
        },
        variant: 'danger',
      },
    ];
  };

  return !loading.list && listAppUpdates.appUpdates.length === 0 ? (
    <EmptyState
      title={tMaster('paramsPage.params.general.empty.title')}
      description={tMaster('paramsPage.params.general.empty.description')}
    />
  ) : (
    <Table
      options={options}
      showSearch={false}
      columns={columns}
      page={page}
      size={size}
      totalPages={listAppUpdates.totalPages}
      data={listAppUpdates.appUpdates}
      setPage={setPage}
      setSize={setSize}
      loading={loading.list}
      order={order}
      setOrder={setOrder}
    />
  );
});
export default AppUpdateTable;
