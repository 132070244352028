import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { LoadingNotificationType, NotificationType } from './notification-types';
import { OrderTableTypes } from '@/component/Table/types';

export class NotificationStore {
  constructor() {
    makeAutoObservable(this);
    void makePersistable(this, {
      name: 'NotificationStore',
      properties: [],
    });
  }
  notificationsList: NotificationType[] = [];
  page: number = 1;
  size: number = 10;
  order: OrderTableTypes = { column: 'id', direction: 'desc' };

  loading: LoadingNotificationType = { list: true };

  reset = () => {
    runInAction(() => {
      this.notificationsList = [];
      this.page = 1;
      this.size = 10;
      this.order = { column: 'id', direction: 'desc' };
    });
  };

  setpage = (page: number) => {
    runInAction(() => {
      this.page = page;
    });
  };
  setSize = (size: number) => {
    runInAction(() => {
      this.size = size;
    });
  };
  setOrder = (order: OrderTableTypes) => {
    runInAction(() => {
      this.order = order;
    });
  };
}
