import Colors from '@/utils/colors';
import { observer } from 'mobx-react';
import { IoMdCloseCircle } from 'react-icons/io';
import BadgeStatusApprovals from '../../../../badge-status-approvals';
import { useTranslation } from 'react-i18next';
import { plantedAreaNoteStore } from '@/store';

export const LogError = observer(() => {
  const tPlantedArea = useTranslation('plantedArea').t;
  const { modalStates } = plantedAreaNoteStore;
  const { note } = modalStates;
  if (!note || note.error === false) {
    return null;
  }
  return (
    <div className="flex items-center">
      <div className="w-[10%] h-[80px] justify-center flex relative">
        <IoMdCloseCircle
          color={Colors.error[200]}
          className="absolute top-[25px] bg-white dark:bg-darkblack-600"
          size={30}
        />
        <div className="border-[1px] w-[1px] h-[40px]"></div>
      </div>
      <div className="flex-1">
        <BadgeStatusApprovals note={note} showLevel={false} />
        <p className="dark:text-bgray-200">
          {tPlantedArea('listNotes.modals.modalHistory.waitingAction')}
        </p>
        <p className="text-sm text-error-200">{note?.errorMessage}</p>
      </div>
    </div>
  );
});
