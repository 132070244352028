import SecondaryButton from '@/component/buttons/SecondaryButton';
import Modal from '@/component/modals/modal-default';
import { appUpdateStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export const AppUpdateReleasesModal = observer(() => {
  const { modalState, handleModalState } = appUpdateStore;
  function handleClose() {
    handleModalState('releases', false);
  }
  const tMaster = useTranslation('master').t;
  const tCommon = useTranslation('common').t;
  return (
    <Modal
      isActive={modalState.releases}
      handleClose={handleClose}
      variant="sm"
      title={tMaster('paramsPage.params.general.modals.releases.title')}
      footer={
        <div className="flex justify-end">
          <SecondaryButton text={tCommon('actions.close')} onClick={handleClose} />
        </div>
      }
    >
      <div className="min-h-[300px] dark:text-white">
        {modalState.appUpdate?.releases}
      </div>
    </Modal>
  );
});
