import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { useTranslation } from 'react-i18next';
import { NotificationButton } from './components/button';
import { NotificationItem } from './components/item';
import { observer } from 'mobx-react';
import { globalStore } from '@/store';
import { translationI18n } from '@/i18n';

export const NotificationPopup = observer(() => {
  const { myNotifications, setReadAll } = globalStore;
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);
  const id = open ? 'simple-popper' : undefined;
  const tGeneral = useTranslation('general').t;

  return (
    <>
      <NotificationButton handleClick={handleClick} />
      <BasePopup
        id={id}
        open={open}
        anchor={anchor}
        placement="bottom-end"
        className="z-50"
      >
        <div className="notification-popup-wrapper text-left overflow-y-hidden">
          <div
            id="notification-box"
            style={{
              filter: `drop-shadow(12px 12px 40px rgba(0, 0, 0, 0.08))`,
            }}
            className={`absolute right-[0px] w-[400px] transition-all origin-top  rounded-lg bg-white dark:bg-darkblack-600 overflow-y-hidden ${
              open ? ' block introAnimation' : 'hidden outAnimation'
            } `}
          >
            <div className="relative w-full pt-[45px] overflow-y-hidden">
              <div className="absolute left-0 top-0 flex h-[45px] w-full items-center justify-between px-6">
                <h3 className="text-xl font-bold text-bgray-900 dark:text-gray-200">
                  {tGeneral('notifications.title')}
                </h3>
                {myNotifications.haveNews && (
                  <Link to="#" onClick={setReadAll}>
                    <span className="text-sm font-semibold text-success-200 hover:underline">
                      {translationI18n('general:notifications.checkReadAll')}
                    </span>
                  </Link>
                )}
              </div>
              <ul className="scroll-style-1 h-[335px] w-full overflow-y-scroll">
                {myNotifications.data.map((notification) => {
                  return (
                    <NotificationItem key={notification.id} notification={notification} />
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </BasePopup>
      <div
        onClick={() => setAnchor(null)}
        id="noti-outside"
        className={`fixed left-0 top-0  h-screen w-screen ${anchor ? 'block' : 'hidden'} z-40`}
      ></div>
    </>
  );
});
