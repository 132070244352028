import Modal from '@/component/modals/modal-default';
import { formatNumber } from '@/utils/helpers/formaters';
import { plantedAreaNoteStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import BadgeStatusApprovals from '../../badge-status-approvals';
import { LogApprove } from './components/log-approve';
import { LogCreatedAt } from './components/log-created-at';
import { LogSynchronize } from './components/log-synchronize';
import { LogUpdate } from './components/log-update';
import { LogNextLevelApprovation } from './components/log-next-level-approvation';
import { LogError } from './components/log-error';

const ModalHistory = () => {
  const { modalStates, handleModal } = plantedAreaNoteStore;
  const { note, history: visible } = modalStates;
  const tPlantedArea = useTranslation('plantedArea').t;

  if (!note) {
    return null;
  }

  return (
    <Modal
      isActive={visible}
      handleClose={() => handleModal('history', false)}
      title={`${tPlantedArea('listNotes.modals.modalHistory.title')} #${formatNumber(note.id, 0)}`}
      badge={<BadgeStatusApprovals note={note} />}
    >
      <div className="w-full pb-5">
        {note?.logs?.length > 0 &&
          note.logs.map((log, index) => {
            if (log.action === 'createdAt') {
              return <LogCreatedAt log={log} key={log.id} />;
            }
            if (log.action === 'synchronize') {
              return <LogSynchronize log={log} key={log.id} />;
            }
            if (log.action === 'approve') {
              return <LogApprove index={index} key={log.id} log={log} />;
            }
            if (log.action === 'update') {
              return <LogUpdate log={log} key={log.id} />;
            }
            // return <LogApprove index={index} key={log.id} log={log} />;
          })}
        <LogNextLevelApprovation />
        <LogError />
      </div>
    </Modal>
  );
};
export default observer(ModalHistory);
