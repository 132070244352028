import ModalActions from '@/component/modals/modal-actions';
import { plantedAreaNoteStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export const ModalReactiveNote = observer(() => {
  const { modalStates, handleModal, loading, onReactiveNote } = plantedAreaNoteStore;
  function handleClose() {
    handleModal('reactive', false);
  }
  const tCommon = useTranslation('common').t;
  const tPlantedArea = useTranslation('plantedArea').t;
  return (
    <ModalActions
      isActive={modalStates.reactive}
      handleClose={handleClose}
      handleSubmit={onReactiveNote}
      variant={'warning'}
      title={tPlantedArea('listNotes.modals.modalReactive.title', {
        cod: modalStates.note?.cod,
      })}
      description={tPlantedArea('listNotes.modals.modalReactive.description')}
      submitText={tCommon('actions.reactive')}
      cancelText={tCommon('actions.cancel')}
      loading={loading.modalReactive}
    />
  );
});
