import moment from 'moment';
import { LogsType } from '../../../../../planted-area-types';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MdModeEditOutline } from 'react-icons/md';
import { compareObjects } from '@/utils/utils';
import { translationI18n } from '@/i18n';

export const LogUpdate = observer(({ log }: { log: LogsType }) => {
  const tPlantedArea = useTranslation('plantedArea').t;
  console.log(log);
  return (
    <div key={log.id} className="flex items-center">
      <div className="w-[10%] h-[80px] justify-center flex relative">
        <div className="absolute top-1 flex items-center justify-center h-[30px] w-[30px] rounded-full bg-warning-500">
          <MdModeEditOutline size={16} color={'white'} />
        </div>
        <div className={`border-[1px] w-[1px] h-[80px]`}></div>
      </div>
      <div className="flex-1">
        <div className="flex">
          <div
            className={`px-2 py-1 text-sm items-center text-nowrap font-semibold justify-center rounded bg-warning-500 text-white`}
          >
            {tPlantedArea('listNotes.modals.modalHistory.edited')}
          </div>
        </div>
        <p className="dark:text-bgray-200">
          {tPlantedArea('listNotes.modals.modalHistory.infoApprove', {
            levelName: log.user.level.levelName,
            date: moment(log.createdAt).format('DD/MM/YYYY'),
            hour: moment(log.createdAt).format('HH:mm'),
            name: log.user.name,
          })}
        </p>
        <p className="dark:text-bgray-200 text-sm">
          {compareObjects(log.oldValue, log.newValue, [
            {
              key: 'area',
              label: translationI18n(
                'plantedArea:listNotes.modals.modalEdit.labels.plantedArea',
              ),
            },
            {
              key: 'seedlingsUseds',
              label: translationI18n(
                'plantedArea:listNotes.modals.modalEdit.labels.seedlingsUsed',
              ),
            },
            {
              key: 'densintyPlant',
              label: translationI18n('plantedArea:listNotes.modals.modalHistory.density'),
            },
            {
              key: 'seedlingsDiscarded',
              label: translationI18n(
                'plantedArea:listNotes.modals.modalEdit.labels.seedlingsDiscarded',
              ),
            },
            {
              key: 'percentageDiscarded',
              label: translationI18n(
                'plantedArea:listNotes.modals.modalHistory.percentageSeedlingsDiscarded',
              ),
            },
            {
              key: 'geneticMaterial.genetic',
              label: translationI18n(
                'plantedArea:listNotes.modals.modalEdit.labels.geneticMaterial',
              ),
            },
          ])}
        </p>
      </div>
    </div>
  );
});
