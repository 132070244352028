import ModalActions from '@/component/modals/modal-actions';
import { plantedAreaNoteStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export const ModalInactiveNote = observer(() => {
  const { modalStates, handleModal, loading, onInactiveNote } = plantedAreaNoteStore;
  function handleClose() {
    handleModal('inactive', false);
  }
  const tCommon = useTranslation('common').t;
  const tPlantedArea = useTranslation('plantedArea').t;
  return (
    <ModalActions
      isActive={modalStates.inactive}
      handleClose={handleClose}
      handleSubmit={onInactiveNote}
      variant={'delete'}
      title={tPlantedArea('listNotes.modals.modalInactive.title', {
        cod: modalStates.note?.cod,
      })}
      description={tPlantedArea('listNotes.modals.modalInactive.description')}
      submitText={tCommon('actions.inactive')}
      cancelText={tCommon('actions.cancel')}
      loading={loading.modalInactive}
    />
  );
});
