import {
  AppUpdateLoadingInterface,
  getListAppUpdatesDataInterface,
  getListAppUpdatesResponseInterface,
  ModalStateAppUpdateInterface,
} from './master-params-general-app-update-types';
import { OptionType } from '@/component/forms/Select/types';
export const inititalStateLoading: AppUpdateLoadingInterface = {
  list: false,
  add: false,
  delete: false,
};

export const initialStateListAppUpdates: getListAppUpdatesResponseInterface = {
  totalItems: 0,
  appUpdates: [],
  totalPages: 0,
  currentPage: 0,
};

export const initialStateListParams: getListAppUpdatesDataInterface = {
  page: 1,
  size: 10,
  column: 'id',
  direction: 'desc',
};

export const initialStateModalState: ModalStateAppUpdateInterface = {
  add: false,
  delete: false,
  releases: false,
  appUpdate: undefined,
};

export const optionsApp: OptionType[] = [{ label: 'Coletor', value: 'colector' }];
