import { UserType } from '@/utils/types/UsersType';
import {
  CompanyDataType,
  LoadingType,
  ModalStatesType,
  TableParamsType,
  UserListType,
} from './company-profile-types';
import { ColumnsTypes, OrderTableTypes } from '@/component/Table/types';
import { checkPermission } from '@/utils/helpers/validations';
import { acceptedsLanguages } from '@/component/header/language-select';
import { translationI18n } from '@/i18n';

export const inititalStateCompanyData: CompanyDataType = {
  id: 0,
  name: '',
  doc: '',
  contact: '',
  manager: '',
  imageName: '',
  imageUrl: '',
  active: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  levels: [],
  permissions: [],
};

export const initialStateLoading: LoadingType = {
  companyData: false,
  changeImage: false,
  userList: false,
};

export const initialStateModal: ModalStatesType = {
  loading: false,
  updateCompany: false,
  addUser: false,
  changeStatusUser: false,
  changeKeyUser: false,
  deleteUser: false,
  changeMasterUser: false,
  user: undefined,
};

export const initialStateTableParamsUser: TableParamsType = {
  page: 1,
  size: 10,
  column: 'id',
  direction: 'asc' as unknown as OrderTableTypes,
  companyId: 0,
  query: '',
};

export const initialStateUserList: UserListType = {
  totalItems: 0,
  users: [],
  totalPages: 0,
  currentPage: 0,
};

export const columnsUser = (isMyCompany: boolean, user: UserType): ColumnsTypes[] => {
  return [
    {
      title: translationI18n('companyProfile:body.screens.userList.columns.cod'),
      field: 'cod',
      type: 'int',
    },
    {
      title: translationI18n('companyProfile:body.screens.userList.columns.name'),
      field: 'name',
      type: 'string',
    },
    {
      title: translationI18n('companyProfile:body.screens.userList.columns.email'),
      field: 'email',
      type: 'string',
    },
    {
      title: translationI18n('companyProfile:body.screens.userList.columns.contact'),
      field: 'contact',
      type: 'string',
    },
    {
      title: translationI18n('companyProfile:body.screens.userList.columns.level'),
      field: 'level.levelName',
      type: 'string',
    },
    {
      title: translationI18n('companyProfile:body.screens.userList.columns.admin'),
      field: 'keyUser',
      type: 'boolean',
    },
    {
      title: translationI18n('companyProfile:body.screens.userList.columns.master'),
      field: 'master',
      type: 'boolean',
      hide: isMyCompany || !user.master,
    },
    {
      title: translationI18n('companyProfile:body.screens.userList.columns.createdAt'),
      field: 'createdAt',
      type: 'datetime',
    },
    {
      title: translationI18n('companyProfile:body.screens.userList.columns.status'),
      field: 'active',
      type: 'active',
    },
    {
      title: translationI18n('companyProfile:body.screens.userList.columns.language'),
      field: 'language',
      type: 'custom',
      custom: (row) => acceptedsLanguages.find((r) => r.value === row.language)?.label,
    },
    {
      title: translationI18n('companyProfile:body.screens.userList.columns.actions'),
      field: 'options',
      type: 'options',
      hide: !(
        checkPermission({ permission: 'permissionUpdate', service: 'users' }) ||
        checkPermission({ permission: 'permissionDelete', service: 'users' }) ||
        user.master ||
        user.keyUser
      ),
    },
  ].filter((x) => !x?.hide) as ColumnsTypes[];
};
