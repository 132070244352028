import { plantedAreaNoteStore } from '@/store';
import Colors from '@/utils/colors';
import moment from 'moment';
import { LogsType } from '../../../../../planted-area-types';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MdOutlineSyncProblem } from 'react-icons/md';
import { IoSyncCircleSharp } from 'react-icons/io5';

export const LogSynchronize = observer(({ log }: { log: LogsType }) => {
  const { modalStates } = plantedAreaNoteStore;
  const { note } = modalStates;
  const tPlantedArea = useTranslation('plantedArea').t;
  if (!note) return null;
  return (
    <div className="flex items-center" key={log.id}>
      <div className="w-[10%] h-[80px] justify-center flex relative">
        {log.newValue.error ? (
          <div className="absolute top-[25px] flex items-center justify-center h-[30px] w-[30px] rounded-full bg-warning-200">
            <MdOutlineSyncProblem color={'white'} size={20} />
          </div>
        ) : (
          <IoSyncCircleSharp
            color={Colors.success[300]}
            className="absolute top-[25px] bg-white dark:bg-darkblack-600"
            size={30}
          />
        )}
        <div className="border-[1px] w-[1px] h-[80px]"></div>
      </div>
      <div className="flex-1">
        <div className="flex">
          <div
            className={`px-2 py-1 text-sm items-center font-semibold justify-center rounded ${log.newValue?.error ? 'bg-warning-200 text-warning-400' : 'bg-success-300'} text-white`}
          >
            {log.newValue?.error
              ? tPlantedArea('listNotes.modals.modalHistory.synchronizedWithError')
              : tPlantedArea('listNotes.modals.modalHistory.synchronized')}
          </div>
        </div>
        <p className="dark:text-bgray-200">
          {tPlantedArea('listNotes.modals.modalHistory.synchronizedInfo', {
            date: moment(note.createdAt).format('DD/MM/YYYY'),
            hour: moment(note.createdAt).format('HH:mm'),
          })}
        </p>
        <p className="text-sm text-error-200">{log.newValue?.errorMessage}</p>
      </div>
    </div>
  );
});
