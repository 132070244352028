import { plantedAreaNoteStore } from '@/store';
import Colors from '@/utils/colors';
import moment from 'moment';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { LogsType } from '../../../../../planted-area-types';

import { observer } from 'mobx-react';
import BadgeStatusApprovals from '../../../../badge-status-approvals';
import { useTranslation } from 'react-i18next';

export const LogApprove = observer(({ log, index }: { log: LogsType; index: number }) => {
  const { modalStates } = plantedAreaNoteStore;
  const { note } = modalStates;
  const tPlantedArea = useTranslation('plantedArea').t;
  return (
    <div key={log.id} className="flex items-center">
      <div className="w-[10%] h-[80px] justify-center flex relative">
        <IoMdCheckmarkCircle
          color={Colors.primary[200]}
          className="absolute top-[25px] bg-white dark:bg-darkblack-600"
          size={30}
        />

        <div
          className={`border-[1px] w-[1px] h-[${note?.approved && index === note.logs.length - 1 ? '40px' : '80px'}]`}
        ></div>
      </div>
      <div className="flex-1">
        <BadgeStatusApprovals note={{ approved: true }} showLevel={false} />
        <p className="dark:text-bgray-200">
          {tPlantedArea('listNotes.modals.modalHistory.infoApprove', {
            levelName: log.user.level.levelName,
            date: moment(log.createdAt).format('DD/MM/YYYY'),
            hour: moment(log.createdAt).format('HH:mm'),
            name: log.user.name,
          })}
        </p>
      </div>
    </div>
  );
});
