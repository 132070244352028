import Modal from '@/component/modals/modal-default';
import ButtonSubmit from '@/component/forms/button-submit';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import Input from '@/component/forms/Input';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Toast } from '@/component/Toast';
import { AddUserDataType, AddUserModalProps } from '../../../types';
import { saveUser, updateUser } from '../../../services';
import Select from '@/component/forms/Select';
import _ from 'lodash';
import { companyProfileStore } from '@/store';
import { observer } from 'mobx-react';
import { emailValidation, phoneValidation } from '@/utils/helpers/validations';
import { acceptedsLanguages } from '@/component/header/language-select';
import { useTranslation } from 'react-i18next';
const { showSuccess } = Toast;
export const AddUserModal = observer(
  ({ isActive, handleClose, user, reloadData = () => {} }: AddUserModalProps) => {
    const {
      getListUser,
      companyData: company,
      levels,
      loading,
      getLevelsData,
    } = companyProfileStore;
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const tCompanyProfile = useTranslation('companyProfile').t;
    const tCommon = useTranslation('common').t;
    const validation = useFormik({
      enableReinitialize: true,

      initialValues: user?.id
        ? {
            id: user?.id,
            name: user?.name || '',
            levelId: user?.level?.id || 0,
            contact: user.contact || '',
            language: user.language || company.language,
          }
        : {
            name: user?.name || '',
            email: user?.email || '',
            contact: user?.contact || '',
            language: user?.language || company.language,
            companyId: user?.company?.id || company?.id,
            levelId: Number(user?.level?.id) || 0,
          },

      validationSchema: Yup.object(
        user?.id
          ? {
              id: Yup.number(),
              name: Yup.string().required(
                tCompanyProfile('body.screens.userList.modals.modalAdd.errors.name'),
              ),
              contact: phoneValidation(),
              levelId: Yup.number()
                .required(
                  tCompanyProfile(
                    'body.screens.userList.modals.modalAdd.errors.levelId.required',
                  ),
                )
                .min(
                  1,
                  tCompanyProfile(
                    'body.screens.userList.modals.modalAdd.errors.levelId.min',
                  ),
                ),
            }
          : {
              name: Yup.string().required(
                tCompanyProfile('body.screens.userList.modals.modalAdd.errors.name'),
              ),
              email: emailValidation,
              contact: phoneValidation(),
              companyId: Yup.number().required(
                tCompanyProfile('body.screens.userList.modals.modalAdd.errors.companyId'),
              ),
              levelId: Yup.number()
                .required(
                  tCompanyProfile(
                    'body.screens.userList.modals.modalAdd.errors.levelId.required',
                  ),
                )
                .min(
                  1,
                  tCompanyProfile(
                    'body.screens.userList.modals.modalAdd.errors.levelId.min',
                  ),
                ),
            },
      ),

      onSubmit: async (values: AddUserDataType) => {
        try {
          setLoadingSubmit(true);
          const result = user?.id ? await updateUser(values) : await saveUser(values);
          showSuccess(result);
          reloadData();
          handleClose();
          getListUser();
          setLoadingSubmit(false);
        } catch (error: any) {
          console.error(error.message);
          setLoadingSubmit(false);
        }
      },
    });

    useEffect(() => {
      getLevelsData();
      if (user?.id) {
        validation.setFieldValue('id', user?.id);
        validation.setFieldValue('name', user?.name);
        validation.setFieldValue('contact', user?.contact);
        validation.setFieldValue('levelId', Number(user?.level?.id));
      } else {
        validation.resetForm();
      }
    }, [user, isActive]);

    return (
      <Modal
        title={`${user?.id ? tCommon('actions.edit') : tCommon('actions.add')} ${tCommon('user')}`}
        isActive={isActive}
        handleClose={handleClose}
        variant="sm"
        footer={
          <div className="flex w-full justify-end space-x-3">
            <SecondaryButton text={tCommon('actions.cancel')} onClick={handleClose} />
            <ButtonSubmit
              title={user?.id ? tCommon('actions.update') : tCommon('actions.save')}
              disabled={loadingSubmit}
              loading={loadingSubmit}
              onClick={() => validation.handleSubmit()}
            />
          </div>
        }
      >
        <form onSubmit={validation.handleSubmit} className="w-full">
          <Input
            disabled={loadingSubmit}
            value={validation.values.name}
            onChange={validation.handleChange('name')}
            onBlur={validation.handleBlur('name')} // Adicione esta linha
            label={tCompanyProfile('body.screens.userList.modals.modalAdd.labels.name')}
            type={'text'}
            error={!!(validation.errors.name && validation.touched.name)}
            errorMessage={validation.errors.name}
          />
          {!user?.id && (
            <Input
              disabled={loadingSubmit}
              value={validation.values.email || ''}
              onChange={validation.handleChange('email')}
              onBlur={validation.handleBlur('email')} // Adicione esta linha
              label={tCompanyProfile(
                'body.screens.userList.modals.modalAdd.labels.email',
              )}
              type={'text'}
              error={!!(validation.errors.email && validation.touched.email)}
              errorMessage={validation.errors.email}
            />
          )}
          <Input
            disabled={loadingSubmit}
            value={validation.values.contact}
            onChange={validation.handleChange('contact')}
            onBlur={validation.handleBlur('contact')}
            label={tCompanyProfile(
              'body.screens.userList.modals.modalAdd.labels.contact',
            )}
            type={'tel'}
            error={!!(validation.errors.contact && validation.touched.contact)}
            errorMessage={validation.errors.contact}
          />
          <Select
            label={tCompanyProfile(
              'body.screens.userList.modals.modalAdd.labels.levelId',
            )}
            disabled={loadingSubmit}
            loading={loading.levels}
            onChange={(value) => validation.setFieldValue('levelId', Number(value))}
            onBlur={validation.handleBlur('levelId')} // Adicione esta linha
            error={!!(validation.errors.levelId && validation.touched.levelId)}
            errorMessage={validation.errors.levelId}
            data={
              levels?.length
                ? [
                    ..._.sortBy(levels, 'level').map((level) => {
                      return {
                        label: `${level.level}. ${level.levelName}`,
                        value: level.id,
                      };
                    }),
                  ]
                : []
            }
            value={validation.values.levelId}
          />
          <Select
            value={validation.values.language}
            label={tCompanyProfile(
              'body.screens.userList.modals.modalAdd.labels.language',
            )}
            data={acceptedsLanguages}
            onChange={(newValue) => validation.setFieldValue('language', newValue)}
          />
        </form>
      </Modal>
    );
  },
);
