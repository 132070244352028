import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import {
  AppUpdateInterface,
  AppUpdateInterfaceSave,
  AppUpdateLoadingInterface,
  getListAppUpdatesResponseInterface,
  ModalStateAppUpdateInterface,
} from './master-params-general-app-update-types';
import {
  initialStateListAppUpdates,
  initialStateModalState,
  inititalStateLoading,
} from './master-params-general-app-update-initital-states';
import {
  deleteAppUpdate,
  getListAppUpdates,
  saveAppUpdate,
  updateAppUpdate,
} from './master-params-general-app-update-services';

import { OrderTableTypes } from '@/component/Table/types';
import { Toast } from '@/component/Toast';
const { showSuccess } = Toast;
export class AppUpdateStore {
  constructor() {
    makeAutoObservable(this);
    void makePersistable(this, {
      name: 'AppUpdateStore',
      properties: [],
    });
  }
  modalState: ModalStateAppUpdateInterface = initialStateModalState;
  loading: AppUpdateLoadingInterface = inititalStateLoading;
  listAppUpdates: getListAppUpdatesResponseInterface = initialStateListAppUpdates;
  page: number = 1;
  size: number = 10;
  order: OrderTableTypes = { column: 'id', direction: 'desc' };

  reset = () => {
    this.modalState = initialStateModalState;
    this.loading = inititalStateLoading;
    this.listAppUpdates = initialStateListAppUpdates;
    this.page = 1;
    this.size = 1;
    this.order = { column: 'id', direction: 'desc' };
  };

  handleLoading = (key: keyof AppUpdateLoadingInterface, newValue: boolean) => {
    runInAction(() => {
      this.loading[key] = newValue;
    });
  };

  setPage = (page: number) => {
    runInAction(() => {
      this.page = page;
    });
  };
  setSize = (size: number) => {
    runInAction(() => {
      this.size = size;
    });
  };
  setOrder = (order: OrderTableTypes) => {
    runInAction(() => {
      this.order = order;
    });
  };

  handleModalState = (
    key: keyof ModalStateAppUpdateInterface,
    newValue: boolean,
    appUpdate: AppUpdateInterface | undefined = undefined,
  ) => {
    runInAction(() => {
      this.modalState = { ...this.modalState, [key]: newValue, appUpdate };
    });
  };

  getListUpdate = async () => {
    this.handleLoading('list', true);
    try {
      const newList = await getListAppUpdates({
        page: this.page,
        size: this.size,
        column: this.order.column,
        direction: this.order.direction,
      });
      runInAction(() => {
        this.listAppUpdates = newList;
      });
      this.handleLoading('list', false);
    } catch (error: any) {
      console.log(error.message);
      this.handleLoading('list', false);
    }
  };

  onSubmit = async (appUpdate: AppUpdateInterfaceSave) => {
    try {
      this.handleLoading('add', true);

      const result = appUpdate?.id
        ? await updateAppUpdate(appUpdate)
        : await saveAppUpdate(appUpdate);
      showSuccess(result);
      if (appUpdate?.id) {
        runInAction(() => {
          const newList = this.listAppUpdates.appUpdates.map((row) => {
            if (row.id === appUpdate.id) {
              return result.data;
            } else {
              return row;
            }
          });
          this.listAppUpdates = { ...this.listAppUpdates, appUpdates: newList };
        });
      } else {
        this.getListUpdate();
      }
      this.handleLoading('add', false);
      this.handleModalState('add', false);
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('add', false);
    }
  };

  onDelete = async () => {
    try {
      this.handleLoading('delete', true);
      const result = await deleteAppUpdate(
        this.modalState.appUpdate?.id ? this.modalState.appUpdate.id : 0,
      );
      showSuccess(result);
      await this.getListUpdate();
      this.handleLoading('delete', false);
      this.handleModalState('delete', false);
    } catch (error: any) {
      console.log(error.message);
      this.handleLoading('delete', false);
    }
  };
}
