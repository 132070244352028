import { observer } from 'mobx-react';
import AppUpdateTable from './table';
import { appUpdateStore } from '@/store';
import { useEffect } from 'react';
import { AppUpdateAddModal } from './modals/app-update-add-modal';
import { AppUpdateDeleteModal } from './modals/app-update-delete-modal';
import PrimaryButton from '@/component/buttons/PrimaryButton';
import { IoAdd } from 'react-icons/io5';
import { translationI18n } from '@/i18n';
import { AppUpdateReleasesModal } from './modals/app-update-releases-modal';

export const AppUpdateContent = observer(() => {
  const { getListUpdate, page, size, order, handleModalState } = appUpdateStore;
  useEffect(() => {
    getListUpdate();
  }, [page, size, order]);
  return (
    <div className="bg-white w-[100%] h-[100%] px-4 pt-5 mb-[40px]  rounded-lg dark:bg-darkblack-600 shadow-lg">
      <div className="flex justify-end">
        <PrimaryButton
          icon={IoAdd}
          text={translationI18n('common:actions.add')}
          onClick={() => handleModalState('add', true)}
          loading={false}
        />
      </div>
      <AppUpdateTable />
      <AppUpdateAddModal />
      <AppUpdateDeleteModal />
      <AppUpdateReleasesModal />
    </div>
  );
});
