import logo from '@/assets/images/logo/logo-color.svg';
import './style.css';
import i18n, { translationI18n } from '@/i18n';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
export const Privacy = () => {
  const { newLanguage } = useParams();
  const language = newLanguage ?? i18n.language;
  const tGeneral = useTranslation('general', { lng: language }).t;
  document.title = tGeneral('privacy.title');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const darkmode = queryParams.get('darkmode') === 'dark' ? 'dark' : 'light';
  useEffect(() => {
    document.querySelector('html').classList = darkmode;
  }, [darkmode]);
  return (
    <div className="dark:bg-darkblack-700 dark:text-white w-full px-4 text-justify overflow-y-scroll max-h-screen">
      <img src={logo} className="w-[300px] hidden md:block" />
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong>{tGeneral('privacy.title')}</strong>
        </li>
      </ol>
      <p className="recuo1">&nbsp;</p>
      <ol>
        <li>
          <strong>{tGeneral('privacy.sectionOne.title')}</strong>
        </li>
      </ol>
      <p className="recuo1">&nbsp;</p>
      <p>
        {tGeneral('privacy.sectionOne.itemOne.partOne')}
        <strong>{translationI18n('common:onForestName')}</strong>
        {tGeneral('privacy.sectionOne.itemOne.partTwo')}
        <a href="http://www.onforest.com.br">www.onforest.com.br</a>
        {tGeneral('privacy.sectionOne.itemOne.partThree')}
      </p>
      <p>
        {tGeneral('privacy.sectionOne.itemTwo.partOne')}
        <a href="http://www.onforest.com.br">www.onforest.com.br</a>
        {tGeneral('privacy.sectionOne.itemTwo.partOne')}
        <strong>{translationI18n('common:onForestName')}</strong>
        {tGeneral('privacy.sectionOne.itemTwo.partOne')}
      </p>
      <p>{tGeneral('privacy.sectionOne.itemThree')}</p>
      <p className="recuo1">&nbsp;</p>
      <ol>
        <li>
          <strong>{tGeneral('privacy.sectionTwo.title')}</strong>
        </li>
      </ol>
      <p className="recuo1">&nbsp;</p>
      <p>{tGeneral('privacy.sectionTwo.intro')}</p>
      <ul className="recuo1">
        <li>
          ● <strong>{tGeneral('privacy.sectionTwo.itemOne.intro')}</strong>
          {tGeneral('privacy.sectionTwo.itemOne.text')}
        </li>
        <li>
          ● <strong>{tGeneral('privacy.sectionTwo.itemTwo.intro')}</strong>
          {tGeneral('privacy.sectionTwo.itemTwo.text')}
        </li>
        <li>
          ● <strong>{tGeneral('privacy.sectionTwo.itemThree.intro')}</strong>
          {tGeneral('privacy.sectionTwo.itemThree.text')}
        </li>
      </ul>
      <p className="recuo1">&nbsp;</p>
      <ol>
        <li>
          <strong>{tGeneral('privacy.sectionThree.title')}</strong>
        </li>
      </ol>
      <p className="recuo1">&nbsp;</p>
      <p>{tGeneral('privacy.sectionThree.intro')}</p>
      <ul className="recuo1">
        <li>
          ● <strong>{tGeneral('privacy.sectionThree.itemOne.intro')}</strong>
          {tGeneral('privacy.sectionThree.itemOne.text')}
        </li>
        <li>
          ● <strong>{tGeneral('privacy.sectionThree.itemTwo.intro')}</strong>
          {tGeneral('privacy.sectionThree.itemTwo.text')}
        </li>
        <li>
          ● <strong>{tGeneral('privacy.sectionThree.itemThree.intro')}</strong>
          {tGeneral('privacy.sectionThree.itemThree.text')}
        </li>
        <li>
          ● <strong>{tGeneral('privacy.sectionThree.itemFour.intro')}</strong>
          {tGeneral('privacy.sectionThree.itemFour.text')}
        </li>
      </ul>
      <p className="recuo1">&nbsp;</p>
      <ol>
        <li>
          <strong>{tGeneral('privacy.sectionFour.title')}</strong>
        </li>
      </ol>
      <p className="recuo1">&nbsp;</p>
      <p>{tGeneral('privacy.sectionFour.intro')}</p>
      <ul className="recuo1">
        <li>
          ● <strong>{tGeneral('privacy.sectionFour.itemOne.intro')}</strong>
          {tGeneral('privacy.sectionFour.itemOne.text')}
        </li>
        <li>
          ● <strong>{tGeneral('privacy.sectionFour.itemTwo.intro')}</strong>
          {tGeneral('privacy.sectionFour.itemTwo.text')}
        </li>
        <li>
          ● <strong>{tGeneral('privacy.sectionFour.itemThree.intro')}</strong>
          {tGeneral('privacy.sectionFour.itemThree.text')}
        </li>
        <li>
          ● <strong>{tGeneral('privacy.sectionFour.itemFour.intro')}</strong>
          {tGeneral('privacy.sectionFour.itemFour.text')}
        </li>
        <li>
          ● <strong>{tGeneral('privacy.sectionFour.itemFive.intro')}</strong>
          {tGeneral('privacy.sectionFour.itemFive.text')}
        </li>
      </ul>
      <p>{tGeneral('privacy.sectionFour.itemSix')}</p>
      <p className="recuo1">&nbsp;</p>
      <ol>
        <li>
          <strong>{tGeneral('privacy.sectionFive.title')}</strong>
        </li>
      </ol>
      <p className="recuo1">&nbsp;</p>
      <p>{tGeneral('privacy.sectionFive.text')}</p>
      <p className="recuo1">&nbsp;</p>
      <ol>
        <li>
          <strong>{tGeneral('privacy.sectionSix.title')}</strong>
        </li>
      </ol>
      <p className="recuo1">&nbsp;</p>
      <p>{tGeneral('privacy.sectionSix.itemOne')}</p>
      <p>{tGeneral('privacy.sectionSix.itemTwo')}</p>
      <p className="recuo1">&nbsp;</p>
      <ol>
        <li>
          <strong>{tGeneral('privacy.sectionSeven.title')}</strong>
        </li>
      </ol>
      <p className="recuo1">&nbsp;</p>
      <p>{tGeneral('privacy.sectionSeven.itemOne')}</p>
      <p>{tGeneral('privacy.sectionSeven.itemTwo')}</p>
      <p className="recuo1">&nbsp;</p>
      <ol>
        <li>
          <strong>{tGeneral('privacy.sectionEight.title')}</strong>
        </li>
      </ol>
      <p className="recuo1">&nbsp;</p>
      <p>{tGeneral('privacy.sectionEight.itemOne')}</p>
      <p>
        {tGeneral('privacy.sectionEight.itemTwo')}
        <a href="mailto:contato@onforest.com.br">contato@onforest.com.br</a>.
      </p>
      <p className="recuo1">&nbsp;</p>
      <ol>
        <li>
          <strong>{tGeneral('privacy.sectionNine.title')}</strong>
        </li>
      </ol>
      <p className="recuo1">&nbsp;</p>
      <p>{tGeneral('privacy.sectionNine.text')}</p>
      <p className="recuo1">&nbsp;</p>
      <ol>
        <li>
          <strong>{tGeneral('privacy.sectionTen.title')}</strong>
        </li>
      </ol>
      <p className="recuo1">&nbsp;</p>
      <p>{tGeneral('privacy.sectionTen.text')}</p>
      <p className="recuo1">&nbsp;</p>
    </div>
  );
};
