import { plantedAreaNoteStore } from '@/store';
import Colors from '@/utils/colors';
import moment from 'moment';
import { LogsType } from '../../../../../planted-area-types';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MdRunCircle } from 'react-icons/md';

export const LogCreatedAt = observer(({ log }: { log: LogsType }) => {
  const { modalStates } = plantedAreaNoteStore;
  const { note } = modalStates;
  const tPlantedArea = useTranslation('plantedArea').t;
  if (!note) return null;
  return (
    <div className="flex items-center" key={log.id}>
      <div className="w-[10%] h-[80px] justify-center flex relative">
        <MdRunCircle
          color={Colors.gray[300]}
          className="absolute top-[25px] z-10 bg-white dark:bg-darkblack-600"
          size={30}
        />
        <div className="border-[1px] w-[1px] top-[40px] absolute h-[40px]"></div>
      </div>
      <div className="flex-1">
        <div className="flex">
          <div
            className={`px-2 py-1 text-sm items-center font-semibold justify-center rounded bg-bgray-200 text-gray-400`}
          >
            {tPlantedArea('listNotes.modals.modalHistory.colected')}
          </div>
        </div>
        <p className="dark:text-bgray-200">
          {tPlantedArea('listNotes.modals.modalHistory.colectedInfo', {
            date: moment(note.createdAtMobile).format('DD/MM/YYYY'),
            hour: moment(note.createdAtMobile).format('HH:mm'),
            name: note.user.name,
          })}
        </p>
      </div>
    </div>
  );
});
