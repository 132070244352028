import { useMemo } from 'react';
import { InputProps } from './types';
import { InputMask } from '@react-input/mask';
import { observer } from 'mobx-react';
import { userStore } from '@/store';
import { InputDatePicker } from './DatePicker';
import { InputDateTimePicker } from './DateTimePicker';

const Input = ({
  label,
  placeholder = '',
  value,
  type,
  error = false,
  errorMessage,
  warning = false,
  warningMessage,
  required = false,
  numRows = 3,
  disabled = false,
  additionalInfo = '',
  ...restProps
}: InputProps) => {
  const className = `${disabled ? 'text-bgray-500 dark:text-bgray-500' : 'text-bgray-800 dark:text-white'}  text-md border ${
    error ? 'border-error-300' : warning ? 'border-warning-300' : 'border-bgray-400'
  }  h-[40px] w-full focus:border-primary-200 focus:ring-0 rounded-lg px-4 placeholder:text-bgray-500 placeholder:text-base dark:bg-darkblack-500  `;
  const { theme } = userStore;
  const inputElement = (
    <input
      {...restProps}
      type={type}
      value={value}
      style={{ colorScheme: theme as 'dark' | 'light' }}
      className={className}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
  const inputArea = (
    <textarea
      {...restProps}
      rows={numRows}
      value={value}
      style={{ height: 20 * numRows }}
      className={className}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
  const InputDate = useMemo(() => {
    return (
      <InputDatePicker
        value={value}
        setValue={restProps.onChange}
        className={`${className} ${value === null ? 'input-date-empty' : ''}`}
      />
    );
  }, [value, error, warning, disabled]);
  const InputDateTime = useMemo(() => {
    return (
      <InputDateTimePicker
        value={value}
        setValue={restProps.onChange}
        className={`${className} ${value === null ? 'input-date-empty' : ''}`}
      />
    );
  }, [value, error, warning, disabled]);

  const maskedInput =
    type === 'cnpj' || type === 'tel' ? (
      <InputMask
        {...restProps}
        value={value}
        replacement={{ _: /\d/ }}
        className={className}
        mask={type === 'cnpj' ? '__.___.___/____-__' : '(__) _ ____-____'}
        placeholder={placeholder}
        disabled={disabled}
      />
    ) : type === 'textarea' ? (
      inputArea
    ) : type === 'date' ? (
      InputDate
    ) : type === 'datetime' ? (
      InputDateTime
    ) : (
      inputElement
    );

  return (
    <div className="mb-3">
      <div className="justify-between items-center flex">
        {label && (
          <label
            className={`block text-md mb-1 text-md font-medium ${
              error ? 'text-error-300' : warning ? 'text-warning-300' : 'text-bgray-600'
            } dark:text-white`}
          >
            {`${label} ${required ? '*' : ''}`}
          </label>
        )}
        <div
          className={`block text-md mb-1 text-md font-medium ${
            error ? 'text-error-300' : warning ? 'text-warning-300' : 'text-bgray-600'
          } dark:text-white`}
        >
          {additionalInfo}
        </div>
      </div>
      {maskedInput}
      {error && <p className="text-error-300 text-base mt-1">{errorMessage}</p>}
      {warning && !error && (
        <p className="text-warning-300 text-base mt-1">{warningMessage}</p>
      )}
    </div>
  );
};

export default observer(Input);
