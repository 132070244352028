import SecondaryButton from '@/component/buttons/SecondaryButton';
import ButtonSubmit from '@/component/forms/button-submit';
import Modal from '@/component/modals/modal-default';
import { translationI18n } from '@/i18n';
import { appUpdateStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Input from '@/component/forms/Input';
import Select from '@/component/forms/Select';
import { optionsApp } from '../../master-params-general-app-update-initital-states';
import { useMemo } from 'react';
import { getUTC } from '@/utils/utils';

export const AppUpdateAddModal = observer(() => {
  const { modalState, handleModalState, loading, onSubmit } = appUpdateStore;
  const { add: modalActive, appUpdate } = modalState;
  const tMaster = useTranslation('master').t;
  const handleClose = () => {
    handleModalState('add', false);
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: appUpdate?.id
      ? {
          id: appUpdate.id,
          app: appUpdate.app || 'colector',
          version: appUpdate.version,
          updateDate: new Date(appUpdate.updateDate),
          releases: appUpdate.releases,
        }
      : {
          app: 'colector',
          version: '',
          updateDate: null,
          releases: '',
        },

    validationSchema: Yup.object({
      version: Yup.string().required(translationI18n('common:validations.fieldRequired')),
      updateDate: Yup.string().required(
        translationI18n('common:validations.fieldRequired'),
      ),
      releases: Yup.string().required(
        translationI18n('common:validations.fieldRequired'),
      ),
    }),

    onSubmit: onSubmit,
  });

  useMemo(() => {
    if (modalActive) {
      if (appUpdate?.id) {
        console.log(appUpdate.updateDate);
        validation.setFieldValue('app', appUpdate.app);
        validation.setFieldValue('version', appUpdate.version);
        // validation.setFieldValue('updateDate', new Date(appUpdate.updateDate));
        validation.setFieldValue('releases', appUpdate.releases);
      }
    } else {
      validation.resetForm();
    }
  }, [modalActive]);
  return (
    <Modal
      title={`${appUpdate?.id ? tMaster('paramsPage.params.general.modals.add.titleEdit', { version: appUpdate.id }) : tMaster('paramsPage.params.general.modals.add.titleAdd')}`}
      isActive={modalActive}
      variant="sm"
      handleClose={handleClose}
      footer={
        <div className="flex w-full justify-end space-x-3">
          <SecondaryButton
            text={translationI18n('common:actions.cancel')}
            onClick={handleClose}
          />
          <ButtonSubmit
            title={
              appUpdate?.id
                ? translationI18n('common:actions.update')
                : translationI18n('common:actions.save')
            }
            disabled={loading.add}
            loading={loading.add}
            onClick={() => validation.handleSubmit()}
          />
        </div>
      }
    >
      <form onSubmit={validation.handleSubmit} className="w-full">
        <Select
          disabled={loading.add}
          value={validation.values.app}
          data={optionsApp}
          onChange={(value) => validation.setFieldValue('app', value)}
          onBlur={validation.handleBlur('app')} // Adicione esta linha
          label={tMaster('paramsPage.params.general.columns.app')}
          error={!!(validation.errors.app && validation.touched.app)}
          errorMessage={validation.errors.app}
        />
        <Input
          disabled={loading.add}
          value={validation.values.version}
          onChange={validation.handleChange('version')}
          onBlur={validation.handleBlur('version')} // Adicione esta linha
          label={tMaster('paramsPage.params.general.columns.version')}
          type={'text'}
          error={!!(validation.errors.version && validation.touched.version)}
          errorMessage={validation.errors.version}
        />
        <Input
          disabled={loading.add}
          value={validation.values.updateDate}
          onChange={(date) => {
            validation.setFieldValue('updateDate', date);
          }}
          onBlur={validation.handleBlur('updateDate')} // Adicione esta linha
          label={tMaster('paramsPage.params.general.columns.updateDate', {
            utc: getUTC(),
          })}
          type={'datetime'}
          error={!!(validation.errors.updateDate && validation.touched.updateDate)}
          errorMessage={validation.errors.updateDate}
        />

        <Input
          disabled={loading.add}
          value={validation.values.releases}
          onChange={validation.handleChange('releases')}
          onBlur={validation.handleBlur('releases')} // Adicione esta linha
          label={tMaster('paramsPage.params.general.columns.releases')}
          type={'textarea'}
          error={!!(validation.errors.releases && validation.touched.releases)}
          errorMessage={validation.errors.releases}
        />
      </form>
    </Modal>
  );
});
