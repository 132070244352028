import ModalActions from '@/component/modals/modal-actions';
import { appUpdateStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export const AppUpdateDeleteModal = observer(() => {
  const { modalState, loading, handleModalState, onDelete } = appUpdateStore;
  const tMaster = useTranslation('master').t;
  const tCommon = useTranslation('common').t;
  function handleModalCloseDelete() {
    handleModalState('delete', false);
  }
  return (
    <ModalActions
      isActive={modalState.delete}
      handleClose={handleModalCloseDelete}
      handleSubmit={onDelete}
      variant={'delete'}
      title={tMaster('paramsPage.params.general.modals.delete.title', {
        id: modalState.appUpdate?.id,
      })}
      description={tMaster('paramsPage.params.general.modals.delete.description')}
      submitText={tCommon('actions.delete')}
      cancelText={tCommon('actions.cancel')}
      loading={loading.delete}
    />
  );
});
